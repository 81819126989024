import { createApp } from 'vue'
import VueGtag from "vue-gtag";
import App from './App.vue'
import router from './router'
import './styles/tailwind.css';

createApp(App)
  .use(router)
  .use(VueGtag,
    {
      config: {
        id: "G-EQL53Z0LPJ",
      }
    },
    router
  )
  .mount("#app");