<template>
  <div class="relative overflow-x-hidden">
    <div class="relative max-w-2xl lg:max-w-3xl mx-auto py-16 lg:py-24 px-6 md:px-12 text-lg md:text-xl text-gray-700 leading-relaxed">
      <div class="prose relative">
        <h1>Barespend's Privacy Policy</h1>
        <p>
          Barespend is not in the business of making money off your data. Everything we collect is used
          exclusively to help us provide you with the best service possible.
        </p>

        <h2>Identity & Access</h2>

        <p>
          When you sign up for Barespend, we ask for your name, company name, and email address. That’s
          just so you can personalize your new account, and we can send you invoices, updates, or
          other essential information. We’ll never sell or rent your personal info to third parties,
          and we won’t use your name or company in marketing statements without your permission,
          either.
        </p>

        <p>
          When you pay for Barespend, everything is handled by our payment processor Stripe. Through
          Stripe, we can see records of your payments and the last four digits and expiration date
          of credit cards.
        </p>

        <p>
          When you contact Barespend with a question or to ask for help, we’ll keep that correspondence,
          and the email address, for future reference. When you browse our marketing pages, we’ll
          track that for statistical purposes (like conversion rates and to test new designs). We
          also store any information you volunteer, like surveys, for as long as it makes sense.
        </p>

        <p>The only times we’ll ever share your info:</p>

        <ul>
          <li>To provide products or services you’ve requested, with your permission.</li>
          <li>
            To investigate, prevent, or take action regarding illegal activities, suspected fraud,
            situations involving potential threats to the physical safety of any person, violations
            of our
            <a href="/terms-and-conditions">Terms of Service</a>
            or as otherwise required by law.
          </li>
          <li>
            If Barespend is acquired by or merged with another company — we don’t plan on that, but if
            it happens — we’ll notify you well before any info about you is transferred and becomes
            subject to a different privacy policy.
          </li>
        </ul>

        <h2>Your Rights With Respect to Your Information</h2>

        <p>
          You may have heard about the General Data Protection Regulation (“GDPR”) in Europe. GDPR
          gives people under its protection certain rights with respect to their personal
          information collected by us on the Site. Accordingly, Barespend recognizes and will comply
          with GDPR and those rights, except as limited by applicable law. The rights under GDPR
          include:
        </p>

        <ul>
          <li>Right of Access.</li>
          <li>Right to Correction.</li>
          <li>Right to Erasure.</li>
          <li>Right to Complain.</li>
          <li>Right to Restrict Processing.</li>
          <li>Right to Object.</li>
          <li>Right to Portability.</li>
          <li>Right to not be subject to Automated Decision-Making.</li>
        </ul>

        <p>
          If you have questions about exercising these rights or need assistance, please contact us
          at support@barespend.com.
        </p>

        <h2>Processors we use</h2>

        <p>
          As part of the services we provide, and only to the extent necessary, we may use certain
          third party processors to process some or all of your personal information.
        </p>

        <ul>
          <li>Microsoft Azure</li>
          <li>Mixpanel</li>
          <li>Plaid</li>
          <li>Stripe</li>
          <li>Twilio</li>
        </ul>

        <h2>Law enforcement</h2>

        <p>
          Barespend won’t hand your data over to law enforcement unless a court order says we have to.
          We flat-out reject requests from local and federal law enforcement when they seek data
          without a court order. And unless we’re legally prevented from it, we’ll always inform you
          when such requests are made.
        </p>

        <h2>Security & Encryption</h2>

        <p>
          All data is encrypted via SSL/TLS when transmitted from our servers to your browser. The
          database backups are also encrypted. Data isn’t encrypted while it’s live in our database
          (since it needs to be ready to send to you when you need it), but we go to great lengths
          to secure your data at rest. For more information about how we keep your information
          secure, visit our <a href="/security">security page</a>.
        </p>

        <h2>Data Retention</h2>

        <p>
          Server and application logs are retained for 30 days, after which they are permanently
          deleted. Application analytics will be permanently deleted on request.
        </p>

        <h2>Location of Site and Data</h2>

        <p>
          This Site is operated in the United States. If you are located in the European Union or
          elsewhere outside of the United States, please be aware that any information you provide
          to us will be transferred to the United States. By using our Site, participating in any of
          our services and/or providing us with your information, you consent to this transfer.
        </p>

        <h2>Changes & questions</h2>

        <p>
          Barespend may update this policy. We’ll notify you about significant changes by emailing the
          account owner. You can access, change or delete your personal information at any time by
          contacting our support team.
        </p>

        <p>
          Questions about this privacy policy? Please get in touch and we’ll be happy to answer
          them!
        </p>
      </div>
    </div>
  </div>
</template>
