<template>
    <!-- Hero section -->
    <div class="relative">
      <div class="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div class="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
          <div class="absolute inset-0">
            <img class="h-full w-full object-cover" src="https://images.unsplash.com/photo-1517048676732-d65bc937f952?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100" alt="People working on laptops" />
            <div class="absolute inset-0 bg-gradient-to-r from-purple-800 to-indigo-700 mix-blend-multiply" />
          </div>
          <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h1 class="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
              <span class="block text-white">Real-time spend automation</span>
              <span class="block text-indigo-200">for growing companies</span>
            </h1>
            <p class="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-200 sm:max-w-3xl">
              Watch employees report expenses as they happen. Have a real-time view of all expenses. Close books without any headaches. Get all of this and more.
            </p>
            <div class="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
              <form id="cta-info-form" v-show="showform" @submit.prevent="submitForm" class="sm:max-w-xl sm:mx-auto lg:mx-0">
                <div class="sm:flex">
                  <div class="min-w-0 flex-1">
                    <label for="email" class="sr-only">Email address</label>
                    <input id="email" type="email" v-model="email" placeholder="Enter your work email" required class="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400 focus:ring-offset-gray-900">
                  </div>
                  <div class="mt-3 sm:mt-0 sm:ml-3">
                    <button type="submit" class="block w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-purple-500 to-indigo-600 text-white font-medium hover:from-purple-600 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400 focus:ring-offset-gray-900">I'm interested</button>
                  </div>
                </div>
                <p class="mt-3 text-xs text-gray-300 sm:mt-4">We care about protecting your data. Read our <router-link to="/privacy-policy" class="font-medium text-white">Privacy Policy</router-link>.</p>
              </form>
              <p id="cta-info-thank-you" v-show="!showform" class="mt-3 text-md text-white" style="display:none">Thank you! We'll get in touch with you shortly.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Alternating Feature Sections -->
    <div class="relative pt-16 pb-32 overflow-hidden">
      <div aria-hidden="true" class="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
      <div class="relative">
        <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
                <span class="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600">
                  <InboxIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div class="mt-6">
                <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                  Real-time visibility into every dollar spent
                </h2>
                <p class="mt-4 text-lg text-gray-500">
                  Access every transaction instantly and know exactly who's spending on what. Track your top line spend or drill into any transaction in real time. From any device, in real-time.
                </p>
                <div class="mt-6">
                  <a href="" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/joeldsouza/30min'});return false;" class="inline-flex bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700">
                    Get a demo
                  </a>
                </div>
              </div>
            </div>
            <div class="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p class="text-base text-gray-500">
                    &ldquo;Our previous expense management software was archaic and lacked the tools we needed to get receipts in and reconciled to our books in a timely manner. We couldn't find a solution that met our requirements until Barespend. Simple, intuitive, and easy to use for the entire company. Our biggest pain points solved!&rdquo;
                  </p>
                </div>
                <footer class="mt-3">
                  <div class="flex items-center space-x-3">
                    <div class="flex-shrink-0 inline-flex rounded-full border-2 border-white">
                      <img class="h-12 w-12 rounded-full" src="@/assets/Jean.png" alt="Chelsea">
                    </div>
                    <div class="ml-4">
                      <div class="text-base font-medium text-gray-700">Jean Aluisi Shore</div>
                      <div class="text-base font-medium text-gray-700">SVP, Corporate Controller, AJ Capital Partners</div>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div class="mt-12 sm:mt-16 lg:mt-0">
            <div class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="@/assets/barespend-dashboard.png" alt="Inbox user interface" />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-24">
        <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div>
                <span class="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600">
                  <SparklesIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div class="mt-6">
                <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                  Ease of use finally meets expense reporting
                </h2>
                <p class="mt-4 text-lg text-gray-500">
                  Even the best-intentioned expense management software would fail if employees don't use it. If your employees have to be trained to submit expense reports in {{ new Date().getFullYear() }}, they definitely won't use it.

                  With Barespend, there's no learning - we work inside the simple, text app that employees are accustomed to in their daily lives!
                </p>
                <div class="mt-6">
                  <a href="" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/joeldsouza/30min'});return false;" class="inline-flex bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700">
                    Get a demo
                  </a>
                </div>
              </div>
            </div>
            <div class="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p class="text-base text-gray-500">
                    &ldquo;Barespend is incredibly simple to use and zen AF. I'm obsessed.&rdquo;
                  </p>
                </div>
                <footer class="mt-3">
                  <div class="flex items-center space-x-3">
                    <div class="flex-shrink-0 inline-flex rounded-full border-2 border-white">
                      <img class="h-12 w-12 rounded-full" src="@/assets/chelsea.jpeg" alt="Chelsea">
                    </div>
                    <div class="ml-4">
                      <div class="text-base font-medium">Chelsea Gruttadauria</div>
                      <div class="text-base font-medium">Customer Experience Manager, Grifin</div>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none" src="@/assets/barespend-easy-reimburse.png" alt="Customer profile user interface" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Gradient Feature Section -->
    <div class="bg-gradient-to-r from-purple-800 to-indigo-700">
      <div class="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
        <h2 class="text-3xl font-extrabold text-white tracking-tight">
          Spend platform built for efficiency
        </h2>
        <p class="mt-4 max-w-3xl text-lg text-purple-200">
          Simple easy to use platform that helps you optimize spend and close the books with ease. Real time information to close the books 5x faster.
        </p>
        <div class="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
          <div v-for="feature in features" :key="feature.name">
            <div>
              <span class="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                <component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" />
              </span>
            </div>
            <div class="mt-6">
              <h3 class="text-lg font-medium text-white">{{ feature.name }}</h3>
              <p class="mt-2 text-base text-purple-200">
                {{ feature.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Logo Cloud -->
    <div id="integrations" class="bg-gray-100">
      <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
          Connect Barespend to your software stack
        </h2>
        <p class="mt-4 max-w-3xl text-lg text-gray-500">
          Barespend works hand-in-hand with your accounting system, powering it up with a real-time sync to help you simplify your accounting workflow.
        </p>
        <div class="mt-14 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
          <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img class="h-12" src="@/assets/qb-logo.svg" alt="QuickBooks" />
          </div>
          <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img class="h-12" src="@/assets/xero-logo.svg" alt="Xero" />
          </div>
          <!-- <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img class="h-12" src="https://www.freshbooks.com/wp-content/uploads/2020/10/freshbooks-logo-1.png" alt="Freshbooks" />
          </div>
          <div class="col-span-1 flex justify-center md:col-span-2 md:col-start-2 lg:col-span-1">
            <img class="h-12" src="https://dvxko57cb34yr.cloudfront.net/wp-content-mk/uploads/2019/04/05134346/Netsuite-Logo-hi-res.png" alt="NetSuite" />
          </div> -->
          <div class="col-span-2 flex justify-center md:col-span-2 md:col-start-4 lg:col-span-1">
            <img class="h-12" src="https://lavoiepllc.com/wp-content/uploads/2020/11/sage-Intacct-preferred_RGB-1.png" alt="Sage Intacct" />
          </div>
        </div>
      </div>
    </div>

    <!-- Stats section -->
    <div class="relative bg-gray-900">
      <div class="h-80 absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
        <div class="h-full w-full xl:grid xl:grid-cols-2">
          <div class="h-full xl:relative xl:col-start-2">
            <img class="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0" src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100" alt="People working on laptops" />
            <div aria-hidden="true" class="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r" />
          </div>
        </div>
      </div>
      <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
        <div class="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
          <!-- <h2 class="text-sm font-semibold tracking-wide uppercase">
            <span class="bg-gradient-to-r from-purple-300 to-indigo-300 bg-clip-text text-transparent">Valuable Metrics</span>
          </h2> -->
          <p class="mt-3 text-3xl font-extrabold text-white">On a mission to empower small businesses</p>
          <p class="mt-5 text-lg text-gray-300">We're committed to help and support the businesses we work and partner with because a rising tide lifts all boats.</p>
          <p class="mt-5 text-base text-gray-300">As entrepreneurs, we know how hard it is to build a successful business because we've been through it ourselves. Now, we get to leverage our experience to help others succeed.</p>
          <!-- <div class="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
            <p v-for="item in metrics" :key="item.id">
              <span class="block text-2xl font-bold text-white">{{ item.stat }}</span>
              <span class="mt-1 block text-base text-gray-300"
                ><span class="font-medium text-white">{{ item.emphasis }}</span> {{ item.rest }}</span
              >
            </p>
          </div> -->
        </div>
      </div>
    </div>

    <!-- CTA Section -->
    <div class="bg-white">
      <div class="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span class="block">Ready to get started?</span>
          <span class="block bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent">Get in touch.</span>
        </h2>
        <div class="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
          <a href="" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/joeldsouza/30min'});return false;" class="flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700">
            Learn more
          </a>
          <!-- <a href="" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/joeldsouza/30min'});return false;" class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-800 bg-indigo-50 hover:bg-indigo-100">
            Get a demo
          </a> -->
        </div>
      </div>
    </div>
</template>

<script>
import {
  DocumentReportIcon,
  DeviceMobileIcon,
  ShoppingBagIcon,
  InboxIcon,
  MenuIcon,
  CreditCardIcon,
  ClipboardListIcon,
  SparklesIcon,
  ShieldCheckIcon,
  XIcon,
} from '@heroicons/vue/outline'
import { ChevronDownIcon } from '@heroicons/vue/solid'

const features = [
  {
    name: 'Simple receipt capture',
    description: 'Employees use text messaging to catalog receipts and Barespend AI fills it in automatically for easy submission.', 
    icon: DeviceMobileIcon,
  },
  {
    name: 'Improve spend controls',
    description: 'Use spend requests and approvals to ensure that company expenses are approved before employees make purchases.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Give every team a budget',
    description: 'Set spend limits for each team to manage overspending and unauthorized vendor charges.', 
    icon: CreditCardIcon,
  },
  {
    name: 'Insights-driven visibility',
    description: 'Giving visibility into every step of the spending process to all stakeholders helps control budgets and build a healthy spend culture.',
    icon: DocumentReportIcon,
  },
  {
    name: 'Automatic audit trail',
    description: 'Barespend creates a full record of every dollar spent so that you can retrieve all documentation needed for booking and if required for an audit.',
    icon: ClipboardListIcon,
  },
  {
    name: 'Vendor management',
    description: 'Automatically track and predict upcoming payments for your company\'s subscriptions and vendors.',
    icon: ShoppingBagIcon,
  },
]
const metrics = [
  { id: 1, stat: '8K+', emphasis: 'Companies', rest: 'use laoreet amet lacus nibh integer quis.' },
  { id: 2, stat: '25K+', emphasis: 'Countries around the globe', rest: 'lacus nibh integer quis.' },
  { id: 3, stat: '98%', emphasis: 'Customer satisfaction', rest: 'laoreet amet lacus nibh integer quis.' },
  { id: 4, stat: '12M+', emphasis: 'Issues resolved', rest: 'lacus nibh integer quis.' },
]

export default {
  name: 'HomePage',
  components: {
    ChevronDownIcon,
    InboxIcon,
    MenuIcon,
    SparklesIcon,
    XIcon,
  },
  setup() {
    return {
      features,
      metrics,
    }
  },
  data() {
    return {
      email: '',
      showform: true
    }
  },
  methods: {
    // submit the form to our backend api
    async submitForm() {
      this.showform = false;

      await fetch('https://newinternalapi.barespend.com/api/signup', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },

        // pass in the information from our form
        body: JSON.stringify(this.email)
      });
    }
  }
}
</script>