<template>
  <div class="relative bg-indigo-800">
    <div class="absolute inset-0">
      <img class="w-full h-full object-cover" src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100" alt="">
      <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
    </div>
    <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
      <h1 class="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">On a mission to empower small businesses</h1>
      <p class="mt-6 text-xl text-indigo-100 max-w-3xl">We're committed to help and support the businesses we work and partner with – because a rising tide lifts all boats.</p>
      <p class="mt-6 text-base text-indigo-100 max-w-3xl">As entrepreneurs, we know how hard it is to build a successful business because we've been through it ourselves. Now, we get to leverage our experience to help others succeed.</p>
    </div>
  </div>
  <div class="mx-auto py-8 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-16">
    <div class="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
      <div class="space-y-5 sm:space-y-4">
        <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">Meet our team</h2>
          <p class="text-xl text-gray-500">We're an interdisciplinary team of humble "do-ers" with deep, practical experience.</p>
      </div>
      <div class="lg:col-span-2">
        <ul role="list" class="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8">
          <li v-for="person in people" :key="person.name">
            <div class="flex items-center space-x-4 lg:space-x-6">
              <img class="w-16 h-16 rounded-full lg:w-20 lg:h-20" :src="person.imageUrl" alt="" />
              <div class="font-medium text-lg leading-6 space-y-1">
                <h3>{{ person.name }}</h3>
                <p class="text-indigo-600">{{ person.role }}</p>
                <p class="text-indigo-600">
                  <a :href="person.linkedinUrl" target="_blank" class="text-gray-400 hover:text-gray-500">
                    <span class="sr-only">LinkedIn</span>
                    <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path fill-rule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clip-rule="evenodd" />
                    </svg>
                  </a>
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
const people = [
  {
    name: 'Joel D\'Souza',
    role: 'Founder (Product)',
    imageUrl:
      require('../../assets/joel-display.jpg'),
    bio:
      'Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.',
    twitterUrl: '#',
    linkedinUrl: 'https://www.linkedin.com/in/joeldsouza',
  },
  {
    name: 'Peter James',
    role: 'Founder (Revenue)',
    imageUrl:
      require('../../assets/pete-display.png'),
    bio:
      'Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.',
    twitterUrl: '#',
    linkedinUrl: 'https://www.linkedin.com/in/peter-james-694ba712',
  },
  // {
  //   name: 'Tom Behrens',
  //   role: 'Engineering',
  //   imageUrl:
  //     'https://media-exp1.licdn.com/dms/image/C4D03AQG4jOGm7Hv7pw/profile-displayphoto-shrink_400_400/0/1516486297444?e=1637193600&v=beta&t=0UQF80wpTL7PoaXWQ6qjs0Fj9ZjNQixcATKoXPPGdvU',
  //   bio:
  //     'Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.',
  //   twitterUrl: '#',
  //   linkedinUrl: 'https://www.linkedin.com/in/behrenst',
  // },
]

export default {
  setup() {
    return {
      people,
    }
  },
}
</script>