import { createRouter, createWebHistory } from "vue-router";
import HomePage1 from "../views/HomePage1.vue";
import HomePage2 from "../views/HomePage2.vue";
import About from "../views/Company/About.vue";
import Security from "../views/Company/Security.vue";
import Privacy from "../views/Legal/Privacy.vue";
import Terms from "../views/Legal/Terms.vue";
import BlogPost1 from "../views/Blog/SpendManagement.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage1,
  },
  {
    path: "/HomePage2",
    name: "HomePage2",
    component: HomePage2,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/security",
    name: "Security",
    component: Security,
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: Privacy,
  },
  {
    path: "/terms-and-conditions",
    name: "Terms and Conditions",
    component: Terms,
  },
  {
    path: "/blog/why-spend-management-is-important-and-how-automation-can-help",
    name: "Why Spend Management Is Important and How Automation Can Help",
    component: BlogPost1,
  },
  {
    path: "/ncideasubmission",
    component: HomePage1,
    beforeEnter() {
        window.location.href = "https://share.vidyard.com/watch/uBFmBpZy8qteEM7rXW6Vg7";
    }
}
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
  }
});

export default router;