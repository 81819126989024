<template>
  <div class="relative overflow-x-hidden">
    <div class="relative max-w-2xl lg:max-w-3xl mx-auto py-16 lg:py-24 px-6 md:px-12 text-lg md:text-xl text-gray-700 leading-relaxed">
      <div class="prose relative">
        <h1>Barespend's Terms of Service</h1>

        <p>
          By using Barespend (“Service”), you are agreeing to be bound by the following terms and
          conditions (“Terms of Service”).
        </p>

        <p>
          VintageFi, Inc. (“Company”) reserves the right to update and change these Terms of Service
          without notice.
        </p>

        <p>Violation of any of the terms below may result in the termination of your account.</p>

        <h2>Account Terms</h2>

        <ol>
          <li>
            You are responsible for maintaining the security of your account and password. The
            Company cannot and will not be liable for any loss or damage from your failure to comply
            with this security obligation.
          </li>

          <li>
            You may not use the Service for any illegal purpose or to violate any laws in your
            jurisdiction (including but not limited to copyright laws).
          </li>

          <li>
            You must provide your legal full name, a valid email address, and any other information
            requested in order to complete the signup process.
          </li>

          <li>
            Your login may only be used by one person – a single login shared by multiple people is
            not permitted.
          </li>
        </ol>

        <h2>Payment, Refunds, Upgrading and Downgrading Terms</h2>

        <p>
          The Service is billed on a subscription basis ("Subscription(s)"). You will be billed in
          advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set either
          on a monthly, quarterly or annual basis, depending on the type of subscription plan you choose 
          when purchasing a Subscription.
        </p>

        <p>
          At the end of each Billing Cycle, your Subscription will automatically renew under the
          exact same conditions unless you cancel it or the Company cancels it. You may cancel your
          Subscription renewal through our website or by contacting Barespend customer support
          (support@barespend.com).
        </p>

        <p>
          A valid payment method, such as a credit card, is required to process the payment for your
          Subscription. You shall provide the Company with accurate and complete billing information
          including full name, company name, address, state, zip code, and a valid payment method
          information. By submitting such payment information, you automatically authorize the
          Company to charge all Subscription fees incurred through your account to any such payment
          instruments.
        </p>

        <h2>Modifications to the Service and Prices</h2>

        <p>
          The Company reserves the right at any time and from time to time to modify or discontinue,
          temporarily or permanently, any part of the Service with or without notice.
        </p>

        <p>
          Prices of all Services are subject to change upon 30 days notice from us. Such notice may
          be provided at any time by posting the changes to the or the Service itself. The Company
          shall not be liable to you or to any third party for any modification, price change,
          suspension or discontinuance of the Service.
        </p>

        <h2>General Conditions</h2>

        <ol>
          <li>
            Your use of the Service is at your sole risk. The service is provided on an “as is” and
            “as available” basis.
          </li>

          <li>
            You understand that the Company uses third party vendors and hosting partners to provide
            the necessary hardware, software, networking, storage, and related technology required
            to run the Service.
          </li>

          <li>
            You must not modify, adapt or hack the Service.
          </li>

          <li>
            You must not modify another website so as to falsely imply that it is associated with
            the Service or the Company.
          </li>

          <li>
            You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the
            Service, use of the Service, or access to the Service without the express written
            permission by the Company.
          </li>

          <li>
            We may, but have no obligation to, remove accounts that we determine in our sole
            discretion are unlawful or violate any party's intellectual property or these Terms of
            Service.
          </li>

          <li>
            Verbal, physical, written or other abuse (including threats of abuse or retribution) of
            any Service customer, Company employee or officer will result in immediate account
            termination.
          </li>

          <li>
            The Company does not warrant that (i) the service will meet your specific requirements,
            (ii) the service will be uninterrupted, timely, secure, or error-free, (iii) the results
            that may be obtained from the use of the service will be accurate or reliable, (iv) the
            quality of any products, services, information, or other material purchased or obtained
            by you through the service will meet your expectations, and (v) any errors in the
            Service will be corrected.
          </li>

          <li>
            You expressly understand and agree that the Company shall not be liable for any direct,
            indirect, incidental, special, consequential or exemplary damages, including but not
            limited to, damages for loss of profits, goodwill, use, data or other intangible losses
            (even if the Company has been advised of the possibility of such damages), resulting
            from: (i) the use or the inability to use the service; (ii) the cost of procurement of
            substitute goods and services resulting from any goods, data, information or services
            purchased or obtained or messages received or transactions entered into through or from
            the service; (iii) unauthorized access to or alteration of your transmissions or data;
            (iv) statements or conduct of any third party on the service; (v) or any other matter
            relating to the service.
          </li>

          <li>
            The failure of the Company to exercise or enforce any right or provision of the Terms of
            Service shall not constitute a waiver of such right or provision. The Terms of Service
            constitutes the entire agreement between you and the Company and govern your use of the
            Service, superseding any prior agreements between you and the Company (including, but
            not limited to, any prior versions of the Terms of Service).
          </li>

          <li>
            You agree to Company's use of your logo in its marketing materials.
          </li>
        </ol>

        <p>
          Questions about the Terms of Service should be sent to support@barespend.com.
        </p>

        <p>
          Any new features that augment or enhance the current Service, including the release of new
          tools and resources, shall be subject to the Terms of Service. Continued use of the
          Service after any such changes shall constitute your consent to such changes.
        </p>
      </div>
    </div>
  </div>
</template>
