<template>
    <div class="relative overflow-x-hidden">
    <div class="relative max-w-2xl lg:max-w-3xl mx-auto py-16 px-6 md:px-12 text-lg md:text-xl text-gray-700">
      <div class="prose relative">
        <h1>
          Barespend's Security Details
        </h1>

        <h2>
          Introduction
        </h2>

        <p>
          As a remote control application, we recognize the importance of excellent security
          practices. While we are a small team, we work hard to punch above our
          weight on security.
        </p>

        <p>
          This document covers our security practices and policies. If you are interested in the
          data we collect and store, please see our <a href="/privacy-policy">privacy policy</a>.
        </p>

        <h2>
          General practices
        </h2>

        <ul>
          <li>
            Access to servers, source code, and third-party tools are secured with two-factor auth.
          </li>
          <li>We use strong, randomly-generated passwords that are never re-used.</li>
          <li>
            Employees and contractors are given the lowest level of access that
            allows them to get their work done. This rarely includes access to
            production systems or data.
          </li>
          <li>
            We use automatic security vulnerability detection tools to alert us when our
            dependencies have known security issues. We are aggressive about applying patches and
            deploying quickly.
          </li>
          <li>We don't copy production data to external devices (like personal laptops).</li>
        </ul>

        <h2>
          Access control and organizational security
        </h2>

        <h3>
          Personnel
        </h3>

        <p>
          Our employees and contractors sign an NDA before gaining access to
          sensitive information. 
        </p>

        <h3>
          Penetration testing
        </h3>

        <p>
          We had a penetration test performed in September of 2021. The assessment
          concluded that <b> "Overall, the security posture of the Barespend
            platform was found to be in line with industry best practices."
          </b> If you'd like a full copy of the results, <a
            href="mailto:support@barespend.com">send us an email</a>.
        </p>

        <h2>
          Authentication
        </h2>

        <p>
          At sign-up, each customer is given an invite link to share with their team. Each team
          member can use that link to set up a new account with their email and password. User
          passwords are hashed using bcrypt before being stored.
        </p>

        <p>
          When a user logs in, they are given a 20-byte authentication token. The token is invalidated after 24 hours of inactivity.
        </p>

        <p>
          All further interaction with the API is done by providing an
          <span>Authorization</span> header with this token.
        </p>

        <h2 id="encryption">
          Encryption
        </h2>

        <p>
          All communication between the Barespend frontend client and our backend is encrypted with TLS 1.2. Our
          backend server is managed by Azure and uses their
          <a href="https://docs.microsoft.com/en-us/azure/security/fundamentals/encryption-atrest"
            >Key Vault</a
          >
          service. User data is stored in Azure SQL and details of their implementation can be
          found
          <a
            href="https://docs.microsoft.com/en-us/azure/azure-sql/database/transparent-data-encryption-tde-overview"
            >here</a
          >.
        </p>

        <h2>
          Data retention/logging
        </h2>

        <p>
          Logs are stored separate from our backend infrastructure in a private CloudWatch area.
        </p>

        <p>
          These logs are retained for 30 days, after which they are permanently deleted.
        </p>

        <p>
          Application analytics can be permanently deleted on request.
        </p>

        <h2>
          Software development practices
        </h2>

        <ul>
          <li>
            Code written by any developer is signed off by at least one other person before
            committing.
          </li>
          <li>
            Code is tested in a staging environment against a QA checklist before deploying to
            production.
          </li>
        </ul>

        <h2>
          Vulnerability detection
        </h2>

        <p>
          Both the frontend client and our backend are regularly scanned for dependencies with known security
          vulnerabilities.
        </p>

        <p>
          Vulnerable dependencies are patched and redeployed rapidly.
        </p>

        <h2>
          Hosting
        </h2>

        <p>
          Our backend server is hosted on Microsoft Azure.
        </p>

        <p>
          Azure's data center operations have been accredited under:
        </p>

        <ul>
          <li>ISO 27001</li>
          <li>SOC 1 and SOC 2/SSAE 16/ISAE 3402 (Previously SAS 70 Type II)</li>
          <li>PCI Level 1</li>
          <li>FISMA High</li>
          <li>Sarbanes-Oxley (SOX)</li>
        </ul>

        <h2>
          FAQs
        </h2>

        <h3 id="data-collected">What user data do you collect?</h3>

        <p>
          We&#39;re not in the business of making money off of data. We do collect information about
          how users are interacting with our app so we can improve the product and provide faster,
          more effective support when issues arise. These events include:
        </p>

        <ul>
          <li>Sign-In and Sign-Out events</li>
          <li>Interaction with features of the app</li>
          <li>Crashes and other errors</li>
          <li>Changes in network availability status</li>
          <li>Changes in connectivity state with our backend server</li>
        </ul>

        <p>In addition, the following metadata is collected by Mixpanel:</p>
        <ul>
          <li>The version of the Barespend being used</li>
          <li>The user's operating system version</li>
          <li>The user's display dimensions</li>
        </ul>

        <p>
          Users are identified in our system by their email address and are asked to provide a name.
          We don&#39;t attempt to collect any demographic information, and don&#39;t log IP
          addresses on incoming connections.
        </p>

        <h3>
          How do I report a potential vulnerability or security concern?
        </h3>

        <p>
          We are continually engaged with a team of researchers in a private bug
          bounty program and therefore do not provide compensation for independent
          reports. However, if you have a concern please email us at
          <a href="mailto:support@barespend.com">support@barespend.com</a>, which will
          notify us very loudly and we&#39;ll get back to you ASAP.
        </p>

        <h3>
          Are you SOC 2 or ISO 27001 certified?
        </h3>
        <p>
          While we&#39;d eventually love to achieve these certifications, we don&#39;t hold them at
          this time.
        </p>

        <!-- <h3>
          Do you conduct background checks on your employees/contractors?
        </h3>
        <p>
          We vet people we work with via reference checks (provided by them and
          through our own networks), but we do not conduct formal background
          checks.
        </p>

        <h3>
          What insurance do you carry?
        </h3>
        <p>
          <ul>
            <li>$3M cyber liability (each occurrence)</li>
            <li>$3M errors and omissions (each occurrence)</li>
            <li>$1M general commercial liability (each occurrence)</li>
            <li>$6M umbrella liability (each occurrence)</li>
          </ul>
        </p> -->

        <h3>
          Any further questions?
        </h3>
        <p>
          Great! Please <a href="mailto:support@barespend.com">email us</a> and we'll happily update this
          doc.
        </p>
      </div>
    </div>
  </div>
</template>