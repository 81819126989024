<template>
    <article class="mx-auto mb-16 max-w-4xl relative" itemscope="" itemtype="http://schema.org/BlogPosting">
        <!-- <div class="absolute bg-white inset-0" style="transform: skewY(-0.5deg) scaleX(1.05);"></div> -->
        <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
            <div class="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg class="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
                <defs>
                <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
                </defs>
                <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg class="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
                <defs>
                <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
                </defs>
                <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg class="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
                <defs>
                <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
                </defs>
                <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
            </div>
        </div>
        <div class="relative px-6 sm:px-24 py-12 sm:py-20">
            <header>
            <h1 class="pb-2" itemprop="name headline">
                <a class="text-3xl sm:text-4xl leading-snug font-bold tracking-tight text-indigo-600 hover:text-indigo-500" href="/blog/why-spend-management-is-important-and-how-automation-can-help">
                Why Spend Management Is Important and How Automation Can Help
                </a>
            </h1>
            <p class="pb-8 text-gray-700 font-medium"><span itemprop="author" itemscope="" itemtype="http://schema.org/Person"><span itemprop="name">Peter James</span></span> •<time datetime="2021-03-10T00:00:00+00:00" itemprop="datePublished">
                Mar 16, 2022
                </time>
            </p>
            </header>

            <div class="prose" itemprop="articleBody">
                <p>Spend management is the strategy companies use to gain insights and control over how money is spent in an organization. Collection, control and close are three key points in the process of spend management.</p>
                <p>Collaboration is needed between several different departments and financial activities, including those who spend and those who administer, to make this process successful. Automation helps eliminate errors, provide real time data and significantly lessens the pain for all parties involved.</p>
                <p>To get a clear picture of where and how your company spends money, you need a reliable and accurate method for managing finances. That method also needs to produce records that are easily accessible when you need to answer questions about spend, budgeting and cash flow. The best way to help that is with business process automation.</p>

                <h2 id="unsupervised-spend">Unsupervised Spend</h2>

                <p>Having a spending plan to optimize investments is a crucial part for any business and especially important to small businesses. It can make the difference whether a company succeeds or fails.</p>
                <p>Spending that occurs without insight as to who spends what and for what purpose can result in duplicate spends, fraud and misappropriation of assets. Without oversight you can and usually do spend too much for the desired result. Automation can provide insights to how much and how efficiently your resources are being utilized. Regardless of who spends the money and for what purpose, having the tools to oversee and enable spending will result in an improved outcome.</p>
                <p>Unsupervised spending makes it difficult to get a clear sense of company finances. It also makes implementing and sticking to a budget next to impossible. Unfortunately, too many businesses end up in dire financial situations due to uncontrolled spend. In worst-case scenarios, spending can bankrupt your company. Understanding how and where your money is being spent makes sure that you are obtaining the best results and returns for your investment.</p>

                <h2 id="impact-of-spend-automation">Impact of Spend Automation</h2>

                <p>Three key aspects of spend control include; collection, control and closing your books. As a fourth pillar you should add collaboration. Obtaining buy-in from all the teams impacted is a must. No buy-in results in no improvements.</p>    
                <p>A good spend automation platform will provide real-time visibility into your company's spending habits as well as providing insights. It provides accurate financial data that's constantly updating to include the latest information on spend. Stale financial information leads to poor spending decisions because of inaccurate data.</p>                
                <p>Real-time insights are a must in today's fast-paced business environment. Mitigate risk with better data and insights while enabling stakeholders to make purchases without delay. Help guide the process by providing the pertinent data and streamlining workflows.</p>
                <p>In the long-term, spend management enables your company to develop clear policies related to expenses, and enforce those policies. The insights provided by your spend automation platform also lets your company improve operational efficiency, increase profits, and plan for the post-pandemic future. Improved spend management also improves budgeting and forecasting.</p>
                
                <p>The more real-time insights and controls you have over company spend, the better equipped your company is to craft strategies for the future.</p>

                <h2 id="automation-change-management">Automation - Change Management</h2>

                <p>Re-imagining the process to achieve the desired results is extremely important. How do you preserve existing relationships while changing the process to save time, money and make your team's lives easier. The switch should be relatively easy. However, make sure you start with a plan for implementation and clearly state what the expected outcomes are.</p>  
    
                <p>Ask these questions: </p>
                <ul>
                    <li>Can I preserve my existing financial relationships through integration?</li>
                    <li>What will this cost?</li>
                    <li>What are the benefits?”</li>
                    <li>What will employees do with the time saved by automating routine business processes?</li>
                    <li>How will I use insights as they become available?"</li>
                </ul>
                <p>Answering these questions will help you get collaboration from stakeholders and ensure desired outcomes and assist with implementation. When you change platforms, adoption is key to success.</p>
    
                <p>The easier the implementation and changing only what needs improvement will help when launching any new platform.</p>
            </div>
        </div>
    </article>
</template>